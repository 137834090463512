import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, NCCNcallout, ReferencesBlock, EndpointDisplay, ComparisonTable, PrimaryClaim } from '../../components';
import CERVICAL from '../../components/StudyDesign/__study-design/cervical'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-158 - Clinical Trial Results | HCP`,
    keywords: `keynote 158, advanced cervical cancer clinical trial results for monotherapy`,
    description: `Health care professionals may find clinical trial results for second-line monotherapy for advanced cervical cancer in KEYNOTE-158.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/cervical-cancer-second-line-monotherapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/cervical-cancer-second-line-monotherapy/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠158 ","description":"Clinical Findings from KEYNOTE⁠-⁠158 (Cohort E)","trialDesign":"A multicenter, nonrandomized, open-label, multicohort trial (n=98)"}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 24;
const jobCode = jobCodes[24].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'FIGO 2014 Stage III-IVA Cervical Cancer', url: '/efficacy/cervical-cancer-combination-chemoradiotherapy/' },
                { text: '1L Combo Therapy Persistent, Recurrent, or Metastatic Cervical Cancer', url: '/efficacy/cervical-cancer-first-line-combination-therapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/cervical-cancer-second-line-monotherapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;158 (Cohort E)' }, 
    { label: 'Study Design from KEYNOTE&#8288;-&#8288;158' }, 
]

const pageReferences = [
    {
        label: "1.",
        text: "Opdivo [package insert]. Princeton, NJ: Bristol Myers Squibb Company; 2024."
    },
    {
        label: "2.",
        text: "Loqtorzi [package insert]. Redwood City, CA: Coherus BioSciences, Inc.; 2024."
    },
    {
        label: "3.",
        text: "Libtayo [package insert]. Tarrytown, NY: Regeneron Pharmaceuticals, Inc.; 2024."
    },
    {
        label: "4.",
        text: "Jemperli [package insert]. Philadelphia, PA; GlaxoSmithKline LLC; 2024."
    },
    {
        label: "5.",
        text: "Zynyz [package insert]. Wilmington, DE: Incyte Corporation; 2024."
    },
    {
        label: "6.",
        text: "Tevimbra [package insert]. San Mateo, CA: BelGene USA, Inc.; 2024."
    },
]

const durationOfResponseTable = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA'
            },
        ],
        [
            {
                label: 'Median DOR',
                dataPoint: 'Not reached',
                dataSubPoint: '(range: 4.1–18.6+ months)<sup>a</sup>'
            },
        ],
        [
            {
                label: 'Patients with responses lasting ≥6 months',
                dataPoint: '91%',
            },
        ],
        [
            {
                label: 'Median follow-up time',
                dataPoint: '11.7 months',
                dataSubPoint: '(range: 0.6–22.7)'
            },
        ],
    ],
    additionalInformation: [
        {
            title: 'Further Study Results',
            text: `Among patients enrolled in KEYNOTE&#8288;-&#8288;158 (Cohort E), no responses were observed in patients whose tumors did not have PD&#8288;-&#8288;L1 expression (CPS <1).`
        }
    ],
    footnotes: [
        {
            label: "a.",
            text: "Based on patients (n=11) with a response by independent review."
        }
    ],
    definitions: "CI = confidence interval; CPS = combined positive score; PD&#8288;-&#8288;1 = programmed death receptor-1; PD‑L1 = programmed death ligand 1."
}


const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">First Anti–PD&#8288;-&#8288;1 Therapy Approved for Advanced Cervical Cancer<sup>1-6</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <EndpointDisplay title="Objective Response Rate (ORR)" limitation="In 77 previously treated patients with recurrent or metastatic cervical cancer whose tumors expressed PD‑L1 (CPS ≥1)" cards={
                                [
                                    {
                                        dataType: 'ORR',
                                        label: 'KEYTRUDA',
                                        rate: '14.3%',
                                        ci: '95% CI, 7.4–⁠24.1',
                                        completeResponseRate: '2.6%',
                                        partialResponseRate: '11.7%',
                                    }
                                ]
                            }
                            />
                            <ComparisonTable title="Duration of Response (DOR)" {...durationOfResponseTable} />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[1].label}>
                            <CERVICAL />
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
